"use strict";

var Headroom = require('headroom.js');
var jQueryBridget = require('jquery-bridget');
var Masonry = require('masonry-layout');
jQueryBridget( 'masonry', Masonry, $ );
require('bootstrap');
require('bootstrap-select');
require('moment/locale/it.js');
require('tempusdominus-bootstrap-4');

(function( $ ) {

	$.fn.setImg = function() {
		var bg = $(this).data('img');
		$(this).css({
			'background-image': 'url(' + bg + ')'
		});
	};

	$(function() {
    
		$('.imgToDiv').each(function(index, elem) {
			$(elem).setImg();
		});

		$('select').selectpicker({
			width: '100%'
		});

		$('.datetimepicker-input').datetimepicker({
			locale: 'it',
			format: 'L'
		});

		var header = document.querySelector(".header.headroom");
		if(header) {
			var headroom  = new Headroom(header, {
				offset: 599
			});
			headroom.init();
		}

		$('.grid-socialwall').masonry({
			itemSelector: '.grid-item',
			columnWidth: '.grid-sizer',
			percentPosition: true
		});

		$('.top .arrow').on('click', function(e) {
			e.preventDefault();
			$('html, body').animate({
                scrollTop: $(".page-home .steps").offset().top
            }, 300);
		});

		$('.footer .up').on('click', function(e) {
			e.preventDefault();
			$('html, body').animate({
                scrollTop: 0
            }, 300);
		});

		$('.annulla').on('click', function(e) {
			e.preventDefault();
			$('.dropdown-menu').removeClass('show');
		});

		$('.collapse').on('show.bs.collapse hide.bs.collapse', function() {
			$(this).prev('.collapse-toggle').find('img').toggleClass('rotated');
		});

		if($('#map').length) {

			var infoBoxOptions = {
				isableAutoPan: false,
				maxWidth: 0,
				pixelOffset: new google.maps.Size(-38, -264),
				zIndex: null,
				boxStyle: {
					padding: "0px 0px 0px 0px",
					width: "250px",
					height: "250px"
	        	},
	        	closeBoxURL : "",
	        	infoBoxClearance: new google.maps.Size(1, 1),
	    		isHidden: false,
	    		pane: "floatPane",
	    		enableEventPropagation: false
			};
			var locations = $('#map').find('.marker');
			var bounds = new google.maps.LatLngBounds();
			var map;
			var infowindow = new InfoBox(infoBoxOptions);
		    var marker;
		    var image;
		    var latlng;
		    var markers = [];

			map = new google.maps.Map(document.getElementById('map'), {
				center: {lat: 45.69601, lng: 9.66721},
				zoom: 15,
				disableDefaultUI: true
			});

		    $(locations).each(function(index, location) {

		      if($(location).data('tipo') == 'standard') {
			    image = 'public/img/Marker_Standard.png';
		      }
		      else if($(location).data('tipo') == 'business') {
		      	image = 'public/img/Marker_Business.png';
		      }
		      else if($(location).data('tipo') == 'disattiva') {
		      	image = 'public/img/Marker_Disattiva.png';
		      }
		      latlng = new google.maps.LatLng($(location).data('lat'), $(location).data('lng'));
		      marker = new google.maps.Marker({
		        position: latlng,
		        map: map,
		        icon: image
		      });
		      markers.push(marker);
      		  bounds.extend(latlng);
      		  if($(location).html()) {
			      google.maps.event.addListener(marker, 'click', (function(marker) {
			        return function() {
			          infowindow.setContent($(location).html());
			          infowindow.open(map, marker);
			        }
			      })(marker));
			  }

		  	});

		  	map.fitBounds(bounds);
		  	if(markers.length == 1) {
			  	setTimeout(function() {
			  		map.setZoom(15);
			  	}, 250);
		  	}

		  	google.maps.event.addListener(map, "click", function(event) {
			    infowindow.close();
			});

	  	}

	});

})(jQuery);